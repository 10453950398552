
import "css/ttnorms/ttnorms";
import Rails from "@rails/ujs";
Rails.start();
window.bootstrap = require("bootstrap");
window.bodymovin = require("lottie-web");
window.$ = require("jquery");
require("slick-carousel");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");

import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
window.alertify = alertify;

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)
dom.i2svg()

import "css/dark.scss";

$(function() {
  
  var animation = bodymovin.loadAnimation({
    container: document.getElementById('lottie'), // Required
    path: 'https://assets3.lottiefiles.com/packages/lf20_ps3eur2l/Test/data.json', // Required
    renderer: 'svg', // Required
    loop: false, // Optional
    autoplay: false, // Optional
    speed: "0.75",
    style: "width: 100%; height: 100%"
  })

  function isElementInViewport (el) {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    if (!el || !el.getBoundingClientRect) {
      return;
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  function onVisibilityChange(el, callback) {
    var old_visible;
    return function () {
        var visible = isElementInViewport(el);
        if (visible != old_visible) {
            old_visible = visible;
            if (typeof callback == 'function') {
                callback();
            }
        }
    }
  }

  $('.feature-row').each(function(i, el) {
    var handler = onVisibilityChange(el, function() {
      if (isElementInViewport(el)) {
        $('.feature-row').removeClass('active');
        $(el).addClass('active');
      }
    });

    // jQuery
    $(window).on('DOMContentLoaded load resize scroll', handler);
  });

  var handler = onVisibilityChange($('#everything-you-need')[0], function() {
    if (!isElementInViewport($('#everything-you-need')[0])) {
      animation.play();
    }
  });
  
  // jQuery
  $(window).on('DOMContentLoaded load resize scroll', handler);

  /* // Non-jQuery
  if (window.addEventListener) {
      addEventListener('DOMContentLoaded', handler, false);
      addEventListener('load', handler, false);
      addEventListener('scroll', handler, false);
      addEventListener('resize', handler, false);
  } else if (window.attachEvent)  {
      attachEvent('onDOMContentLoaded', handler); // Internet Explorer 9+ :(
      attachEvent('onload', handler);
      attachEvent('onscroll', handler);
      attachEvent('onresize', handler);
  }
  */

  $('.slick-it').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });
  
  if (window.matchMedia("(max-width: 576px)").matches) {
    /* the viewport is less than 768 pixels wide */
    $('.cc-slick-it').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      centerPadding: '20px',
      centerMode: true,
      slidesToShow: 1,
      arrows: false
    });
  } 
  

  var cities = [
    "San Francisco",
    "the Bay Area",
    "Fresno",
    "Dallas",
    "Scottsdale",
    "Denver",
    "New York",
    "Long Island",
    "San Diego",
    "Los Angeles",
    "Encino",
    "Memphis",
    "Brooklyn",
    "the Rouge Valley"
  ];
  var index = 0;

  setInterval(function() {
    index++;

    if (index >= cities.length) {
      index = 0;
    }
    var el = document.querySelector(".cities span");
    el.classList.add('opacity-0');
    setTimeout(function() {
      el.innerHTML = cities[index];
      el.classList.remove('opacity-0');
    }, 1000);
    

  }, 3000);

})